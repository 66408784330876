<div class="auth">
    <div class="auth__form">
        <div class="auth__form__wrapper">
            <router-outlet></router-outlet>
        </div>
    </div>
    <div class="auth__visual">
        <video [muted]="true" autoplay="autoplay" loop="loop" playsinline oncanplay="this.play()" onloadedmetadata="this.muted = true">
            <source src="assets/video/Login-Loop.mp4" type="video/mp4">
            Your browser does not support the video tag.
        </video>
    </div>
</div>