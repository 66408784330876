import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApplicationStateService} from '../../services/application-state.service';
import {DomSanitizer} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {MenuItem} from '../../interfaces/IMenuItem';
import {createMenu} from '../../entities/MenuItems';
import {ExternalPage} from '../../entities/externalPage';
import {ExternalPagesUtils} from '../../utilities/externalPagesUtils';
import {MatIconRegistry} from '@angular/material/icon';

@Component({
    selector: 'app-sidebar-navigation',
    templateUrl: './sidebar-navigation.component.html',
    styleUrls: ['./sidebar-navigation.component.scss'],
})
export class SidebarNavigationComponent implements OnInit, OnDestroy {
    // for css
    closedWidth = 64;
    openWidth = 304;
    isHidden = true;
    sidebarWidth = this.closedWidth;

    // for menu
    subscriptions = new Subscription();
    sisenseLoginUrl;
    menu: MenuItem[];
    externalPages: ExternalPage[] = [];
    uploadPages = [];

    constructor(
        private applicationState: ApplicationStateService,
        private sanitizer: DomSanitizer,
        private matIconRegistry: MatIconRegistry) {
        // Add custom facebook icon to material icon registry to be used as a normal mat-icon in our menu
        this.matIconRegistry.addSvgIcon('op_facebook', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/facebook.svg'));
        this.menu = createMenu();
    }

    ngOnInit() {
        const obs = this.applicationState.getState('clientConfiguration').subscribe((conf) => {
            if (this?.externalPages?.length) {
                this.externalPages.forEach(extPage => {
                    if (!extPage) {
                        return;
                    }
                    const moduleName = extPage.underMenuItem;
                    const moduleItemMatch = this.menu.find(({title}) => title === moduleName);
                    if (moduleItemMatch?.children?.length) {
                        moduleItemMatch.children = moduleItemMatch.children.filter(({title}) => title !== extPage.name);
                        moduleItemMatch.hasChildren = !!moduleItemMatch.children.length;
                    }
                });
            }
            this.externalPages = [];
            this.uploadPages = [];
            if (conf?.shinyPages?.length) {
                this.externalPages.push(...this.getAllowedPages(conf.shinyPages, 'allowedShinyPages'));
            }
            if (conf?.uploadTemplates) {
                this.uploadPages.push.apply(this.uploadPages, this.allowedUploadPages(conf.uploadTemplates));
            }
            if (conf?.sisensePages?.length) {
                this.externalPages.push(...this.getAllowedPages(conf.sisensePages, 'allowedSisensePages'));
                this.sisenseLoginUrl = this.sanitizer.bypassSecurityTrustResourceUrl(conf.sisensePages[0]);
            }
            if (conf?.prototypePages?.length) {
                this.externalPages.push(...this.getAllowedPages(conf.prototypePages, 'allowedPrototypePages'));
            }
            this.loadAdminExternalPages();
            this.getPermissions();
        });
        this.subscriptions.add(obs);
    }

    toggle() {
        this.sidebarWidth = this.isHidden ? this.openWidth : this.closedWidth;
        this.isHidden = !this.isHidden;
    }

    /**
     * Update permissions for menu items based on specific permission settings on a user(group) level
     */
    getPermissions() {
        // rebuild menu
        this.menu = createMenu();
        const obs = this.applicationState.getState('permissions').subscribe((userGroup) => {
            for (const resource of userGroup.userGroup.userGroupPermissions.resourceList) {
                switch (resource.module) {
                    case 'Data Overview': {
                        this.addPages('Data Overview');
                        break;
                    }
                    case 'Model Configuration': {
                        this.addPages('Model Configuration');
                        break;
                    }
                    case 'Insights Update': {
                        this.addPages('Insights Update');
                        break;
                    }
                    case 'Channel insights': {
                        this.addChannelPages(resource);
                        break;
                    }
                    case 'Universal Insights': {
                        this.addUniversalInsightsPages(resource);
                        break;
                    }
                    case 'Brand Insights': {
                        this.addBrandInsightsPages(resource);
                        break;
                    }
                    case 'Budget optimization': {
                        this.addPages('Media Budget Optimization');
                        break;
                    }
                    case 'Data Input': {
                        this.addDataInputPages();
                        break;
                    }
                    case 'Media Scenario Planner': {
                        this.addPages('Media Scenario Planner');
                        break;
                    }
                    case 'KPI Segments': {
                        this.addPages('KPI Segments');
                        break;
                    }
                }
            }
            if (this?.externalPages?.length) {
                this.externalPages.forEach(page => this.addItemToMenu(page));
            }
            if (this?.uploadPages?.length) {
                this.uploadPages.forEach(page => this.addItemToMenu(page));
            }
            const menuItem = this.menu.find(elem => elem.title === 'Admin');
            switch (userGroup.accessLevel) {
                case 'ADMIN':
                    menuItem.allowed = true;
                    menuItem.children.find(elem => elem.title === 'Users').allowed = true;
                    menuItem.children.find(elem => elem.title === 'User Groups').allowed = true;
                    menuItem.children.find(elem => elem.title === 'Datasource Admin').allowed = true;
                    menuItem.children.find(elem => elem.title === 'Mapping Type Admin').allowed = true;
                    menuItem.children.find(elem => elem.title === 'Mapping Flow Admin').allowed = true;
                    break;
                case 'SUPER_ADMIN':
                    menuItem.allowed = true;
                    menuItem.children.forEach((v, i, a) => a[i].allowed = true);
                    break;
                case 'REGULAR':
                    menuItem.allowed = false;
                    menuItem.children.find(elem => elem.title === 'Datasource Admin').allowed = false;
                    menuItem.children.find(elem => elem.title === 'Mapping Type Admin').allowed = false;
                    menuItem.children.find(elem => elem.title === 'Mapping Flow Admin').allowed = false;
                    break;
            }
        });
        this.subscriptions.add(obs);
    }

    addItemToMenu(srcMenuItem: ExternalPage) {
        const srcMenuItemLocation = srcMenuItem.underMenuItem;
        const targetMenuItem = this.menu.find(({title}) => title === srcMenuItemLocation);
        if (targetMenuItem) {
            targetMenuItem.allowed = true;
            if (srcMenuItemLocation === 'Homepage') {
                targetMenuItem.url = `/dashboard/homepage/${targetMenuItem.title}`;
            } else {
                // TODO: this logic should theoretically be refactored as a whole and rely on checking weather or not
                // a section 'hasChildren' - this often leads that the structure defined in MenuItems.ts is often useless
                // the old logic is also not correct because it would arbitrarily reset the array if there were no more entries
                // to be added here (but entries can be added elsewhere too)
                targetMenuItem.children ??= [];
                targetMenuItem.children.push({
                    title: srcMenuItem.name,
                    allowed: true,
                    url: srcMenuItem.id ?
                        `${targetMenuItem.url}/upload/${srcMenuItem.id}` : `${targetMenuItem.url}/${srcMenuItem.name}`,
                });
            }
        }
    }

    addChannelPages(row) {
        const menuItem = this.menu.find(elem => elem.title === 'Channel Insights');
        menuItem.allowed = true;
        switch (row.name) {
            case 'Media Explorer':
                menuItem.children.find(elem => elem.title === 'Media Explorer').allowed = true;
                break;
            case 'Attribution':
                menuItem.children.find(elem => elem.title === 'Attribution').allowed = true;
                break;
        }
    }

    addUniversalInsightsPages(row) {
        const menuItem = this.menu.find(elem => elem.title === 'Universal Insights');
        menuItem.allowed = true;
        switch (row.name) {
            case 'Media Effectiveness':
                menuItem.children.find(elem => elem.title === 'Media Effectiveness').allowed = true;
                break;
            case 'Channel Attribution Insights':
                menuItem.children.find(elem => elem.title === 'Channel Attribution Insights').allowed = true;
                break;
            case 'Campaign Attribution Insights':
                menuItem.children.find(elem => elem.title === 'Campaign Attribution Insights').allowed = true;
                break;
            case 'Factors of Influence Insights':
                menuItem.children.find(elem => elem.title === 'Factors of Influence Insights').allowed = true;
                break;
            case 'Short & Long Term Effects':
                menuItem.children.find(elem => elem.title === 'Short & Long Term Effects').allowed = true;
                break;
            case 'Model Validation':
                menuItem.children.find(elem => elem.title === 'Model Validation').allowed = true;
                break;
        }
    }

    addBrandInsightsPages(row) {
        const menuItem = this.menu.find(elem => elem.title === 'Brand Insights');
        menuItem.allowed = true;
        switch (row.name) {
            case 'Brand Attribution Insights':
                menuItem.children.find(elem => elem.title === 'Brand Attribution Insights').allowed = true;
                break;
        }
    }

    addPages(name: string) {
        const menuItem = this.menu.find(elem => elem.title === name);
        menuItem.allowed = true;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private allowedUploadPages(pages) {
        const allowedPages = [];
        const uploadTemps = [];
        const keys = Object.keys(pages);
        for (const i of keys) {
            if (pages[i]) {
                pages[i].underMenuItem = 'Data Input';
                pages[i].id = i;
                uploadTemps.push(pages[i]);
            }
        }
        const sub = this.applicationState.getState('permissions').subscribe(permissions => {
            const allowedUploadPagePermissionsList = permissions.userGroup.userGroupPermissions.allowedUploadTemplateIds;
            allowedUploadPagePermissionsList.forEach(_id => {
                const allowedPage = uploadTemps.find(({id}) => id === _id);
                if (allowedPage) {
                    allowedPages.push(allowedPage);
                }
            });
        });
        this.subscriptions.add(sub);
        return allowedPages;
    }

    private getAllowedPages(clientConfPages: ExternalPage[], userGroupPermissionsPropertyName: string): ExternalPage[] {
        const allowedPages = [];
        const sub = this.applicationState.getState('permissions').subscribe(permissions => {
            const shinyPagePermissionsList = permissions.userGroup.userGroupPermissions[userGroupPermissionsPropertyName];
            clientConfPages.forEach(page => {
                const accessRightsMatch = shinyPagePermissionsList.find(p => p === page.name);
                if (accessRightsMatch) {
                    allowedPages.push(page);
                }
            });
        });
        this.subscriptions.add(sub);
        return allowedPages;
    }

    private addDataInputPages() {
        const modulePage = this.menu.find(elem => elem.title === 'Data Input');
        modulePage.allowed = true;
        const eventsSubTab = modulePage.children.find(elem => elem.title === 'Events');
        eventsSubTab.allowed = true;
    }

    private loadAdminExternalPages() {
        this.externalPages.push(...ExternalPagesUtils.getDefaultExternalPagesForAdmin());
    }
}
