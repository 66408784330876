import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {BreadCrumb, RouteInfo} from '../entities/BreadCrumb';
import {ChildActivationEnd, Router} from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class BreadcrumbService {

    routes = [];
    routesSubject = new BehaviorSubject<any>(null);
    currentRouteSubject = new BehaviorSubject<number>(null);
    breadcrumbsSubject = new Subject<BreadCrumb[]>();

    constructor(private router: Router) {
        // Subscribe to router events and filter for relevant ChildActivationEnd events
        this.router.events.subscribe((event) => {
            // Check if the event is a ChildActivationEnd event and if it's relevant
            if (event instanceof ChildActivationEnd && this.isRelevantChildActivationEnd(event)) {
                const { _loadedRoutes, path } = event.snapshot.routeConfig as any;
                this.routes = [];
                // Process each loaded child route, building and pushing new RouteInfo objects
                _loadedRoutes?.forEach((child: any) =>
                    this.buildAndPushRoutes(child, path)
                );

                // Notify subscribers that the routes have been updated
                this.routesSubject.next(this.routes);
            }
        });
    }

    /**
     * Helper method to determine if the event is a relevant ChildActivationEnd.
     * This method checks if the last meaningful segment of the current URL path
     * (i.e., the portion following the configured route path) matches any of
     * the paths in `_loadedRoutes` (considering dynamic segments) or directly matches the `routeConfig` path.
     *
     * The method identifies this segment by locating the substring after `routeConfig.path`
     * within the full URL path, removing any query parameters and any leading slashes.
     *
     * If the extracted segment does not match any path in `_loadedRoutes` (including dynamic parameters)
     * or the `routeConfig` path, the event is considered irrelevant and will be ignored.
     *
     * Example:
     * - Suppose the URL is '/edit-plan/6616ac8c3e2c0b7512f0ed1d/add-campaign'.
     * - The last segment, 'edit-plan/6616ac8c3e2c0b7512f0ed1d/add-campaign', will be extracted after removing the route path.
     * - The method identifies 'edit-plan/:planId/add-campaign' in `_loadedRoutes` by matching dynamic segments.
     * - If the segment matches a path in `_loadedRoutes` or matches `routeConfig.path`, the event will be processed.
     * - Otherwise, the event will be ignored as it doesn't match the expected path for breadcrumb updates.
     *
     * @param event - The router event to check, assumed to be a ChildActivationEnd event.
     * @returns true if the event is relevant (i.e., the last segment matches a route path), false otherwise.
     */

    private isRelevantChildActivationEnd(event: ChildActivationEnd): boolean {
        // Ensure routeConfig exists in the event's snapshot
        if (!event.snapshot.routeConfig) return false;

        // Retrieve the full URL path from the router state, excluding query parameters
        const fullPath = (event.snapshot as any)._routerState.url.split('?')[0];

        // Get the configured route path from routeConfig
        const routePath = event.snapshot.routeConfig.path;

        // Identify the last segment of the path relative to routePath
        const lastSegmentStart = fullPath.lastIndexOf(routePath) + routePath.length;
        const lastSegment = lastSegmentStart < fullPath.length
            ? fullPath.substring(lastSegmentStart).replace(/^\/+/, '')
            : '';

        // Get `_loadedRoutes` and `path` from routeConfig
        const { _loadedRoutes } = event.snapshot.routeConfig as any;

        // Helper function to check if a route path matches the last segment, accounting for dynamic segments
        const doesPathMatch = (routePath: string, segment: string): boolean => {
            // Convert dynamic segments (e.g., ':planId') into regex patterns
            const pathRegex = new RegExp(
                `^${routePath.replace(/:[^/]+/g, '[^/]+')}$`
            );
            return pathRegex.test(segment);
        };

        // Check if the last segment matches any path in `_loadedRoutes` or the `routeConfig` path
        return (
            _loadedRoutes?.some((route: any) => doesPathMatch(route.path, lastSegment)) ||
            doesPathMatch(routePath, lastSegment)
        );
    }


    public getRoutesObservable() {
        return this.routesSubject.asObservable();
    }

    public getCurrentRouteObservable() {
        return this.currentRouteSubject.asObservable();
    }

    public getBreadcrumbsObservable() {
        return this.breadcrumbsSubject.asObservable();
    }

    public setBreadcrumbs(breadcrumbs: BreadCrumb[]) {
        this.breadcrumbsSubject.next(breadcrumbs);
    }

    public setCurrentRouteId(routeId: number) {
        this.currentRouteSubject.next(routeId);
    }

    public setBreadcrumbTitle(routeId: number, title: string) {
        const route = this.routes.find(obj => {
            return obj.id === routeId;
        });
        if (route) {
            route.breadcrumb.title = title;
        }
        this.routesSubject.next(this.routes);
    }

    public setBreadcrumbPath(routeId: number, path: string) {
        const route = this.routes.find(obj => {
            return obj.id === routeId;
        });
        if (route) {
            route.path = path;
        }
        this.routesSubject.next(this.routes);
    }

    private buildAndPushRoutes(child, parentPath) {
        this.routes.push(new RouteInfo(child.data?.id, parentPath + '/' + child.path, child.data?.breadcrumb));
    }
}
