import {Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {combineLatest, Subscription} from 'rxjs';
import {ApplicationStateService} from '../../services/application-state.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-client-selection',
    templateUrl: './client-selection.component.html',
    styleUrls: ['./client-selection.component.scss']
})
export class ClientSelectionComponent implements OnInit, OnDestroy {
    @Input() hideMenuDropdown = false;

    subscription = new Subscription();
    selectedClient;
    clients = [];
    filteredClients = [];
    clientLogo = '';
    userClientConf;
    isMenuHidden = true;

    @ViewChild('clientSelector') clientSelector: ElementRef;
    @ViewChild('clientDropdown') clientDropdown: ElementRef;

    constructor(private stateObject: ApplicationStateService,
                private http: HttpClient,
                private router: Router,
                private renderer: Renderer2) {
        this.renderer.listen('window', 'click', (e: Event) => {
            if (e.target !== this.clientDropdown?.nativeElement &&
                e.target !== this.clientSelector?.nativeElement &&
                !this.clientSelector?.nativeElement.contains(e.target)) {
                this.isMenuHidden = true;
            }
        });
    }

    ngOnInit() {
        this.subscription.add(
            combineLatest([
                this.stateObject.getState('userGroups'),
                this.stateObject.getState('clientConfiguration'),
                this.stateObject.getState('userClientConfig')
            ]).subscribe(([userGroups, clientConfiguration, userClientConf]) => {
                // Handle userGroups
                this.clients = userGroups.map(({ userGroup: { client } }) => client);

                // Handle clientConfiguration
                this.selectedClient = clientConfiguration.client;
                this.setClientLogo(clientConfiguration.logo);

                // Handle userClientConf
                this.userClientConf = userClientConf;

                // Update filtered clients
                this.updateFilteredClients();
            })
        );
    }

    setClientLogo(logo) {
        if (logo && logo.length) {
            this.clientLogo = environment.base_url + 'logos/' + this.selectedClient + '/' + logo;
        }
    }

    changeClient(selectedClient) {
        if (this.selectedClient !== selectedClient) {
            this.selectedClient = selectedClient;
            this.stateObject.resetState();
            localStorage.setItem('selected_client', this.selectedClient);
            this.stateObject.setInitialState(this.userClientConf, this.selectedClient);
            const clientConfiguration = this.userClientConf.clientConfigurationList.find(({client}) =>
                client === this.selectedClient
            );
            this.setClientLogo(clientConfiguration.logo);
            this.router.navigateByUrl('');
        }
    }

    updateFilteredClients() {
        // Restrict the list of clients based on hideMenuDropdown
        this.filteredClients = this.hideMenuDropdown
            ? [this.selectedClient]
            : this.clients;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    toggleMenu() {
        if (this.filteredClients.length > 1) {
            this.isMenuHidden = !this.isMenuHidden;
        }
    }
}
